<template>
    <div class="ph-container">
        <v-container fluid class="ph-nonex-container">

            <v-dialog v-model="snackbar" persistent max-width="500">
                <v-card>
                    <v-card-text style="background-color: red" class="scrollable-content error">
                        <h2 style="color: white" class="pt-5">Missing Details!</h2>
                        <v-scroll-y style="max-height: 200px; max-width: 200px; overflow: hidden">
                            <div class="pt-5">
                                <h5 style="color: white" v-if="availment_error" class="mb-2">- Service Availment (AVP, NONEX, WU)</h5>

                                <h5 style="color: white" v-if="type_of_sub_agents_error" class="mb-2">- Business Type of Sub Agents</h5>
                                <h5 style="color: white" v-if="company_id_error" class="mb-2">- Company ID</h5>

                                <h3 style="color: white" v-if="branch_error" class="mb-2">Branch with Incomplete Details:</h3>
                                <section v-if="branch_error"><h5 v-for="(item, index) in branch_name" :key="index" style="color: white" class="mb-1">- {{item}}</h5></section>
                            </div>
                        </v-scroll-y>
                    </v-card-text>
                    <v-card-actions style="background-color: red" class="error">
                        <v-spacer></v-spacer>
                        <v-btn color="white" text @click="snackbar = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-row class="ma-3">
                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="headers"
                            :items="salesProspect"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            :search = "search"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-flex md4 class="search">
                                            <v-text-field
                                                v-model="search"
                                                placeholder="Search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field> 
                                        </v-flex>
                                    </v-layout>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.sp_primary_details.account_name`]="{item}">
                                <div style="cursor: pointer;" @click="$router.push({ path: 'sales-prospecting/view-profile/' + item.id})">
                                    {{item.sp_primary_details.account_name}}
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{item}">
                                {{formatDate(item.created_at)}}
                            </template>
                            <template v-slot:[`item.action`]="{item}">
                                <div>

                                    <v-btn
                                        v-if="item.merchant_status == 'New'"
                                        text
                                        color="primary"
                                        @click="checkSp(item.id)"
                                        >
                                        <v-icon>mdi-file-send-outline</v-icon>
                                        <!-- edit final draft encoder -->
                                    </v-btn>

                                    <v-btn
                                        v-if="item.merchant_status == 'Processing' || item.merchant_status == 'Done'"
                                        text
                                        color="primary"
                                        @click="checkSp(item.id)"
                                        >
                                        <v-icon>mdi-eye</v-icon>
                                        <!-- edit final draft encoder -->
                                    </v-btn>

                                </div>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'

export default {
  name: 'Users',
  data () {
    return {
        search: '',
        fab: false,
        salesProspect: [],
        headers: [
            {
                text: 'Account Name',
                sortable: true,
                value: 'sp_primary_details.account_name'
            },
            {
                text: 'SP Number',
                sortable: true,
                value: 'prospective_number'
            },
            {
                text: 'Owner',
                sortable: true,
                value: 'created_by'
            },
            {
                text: 'Date Created',
                sortable: true,
                value: 'created_at'
            },
            {
                text: 'Merchant Status',
                sortable: true,
                value: 'merchant_status'
            },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                align: 'center'
            }
        ],
        dialog: false,
        sp_no: '',
        snackbar: false,
        branch_name: [],
        company_id_error: false,
        type_of_sub_agents_error: false,
        availment_error: false,
        branch_error: false,
        availment: []

    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      salesProspectingForm: 'salesProspecting/salesProspectingForm',
    })
  },
  async mounted () {
    this.reloadList()
    this.$store.commit('salesProspecting/SET_DEFAULT_SP_FORM')
    this.$store.commit('salesProspecting/SET_DEFAULT_SP_FORM_INITIAL_UPLOAD')
    this.$store.commit('salesProspecting/SET_PRIMARY_BUSINESS_ADDRESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARY_CONTACT_INFO_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_OFFICER_DEFAULT')
    this.$store.commit('salesProspecting/SET_OTHER_PRIMARY_DETAILS_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGFILES_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_FILES_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_BRANCH_PERMIT_DEFAULT')
    this.$store.commit('salesProspecting/SET_RENEWABLE_OWNER_AMLA_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_FINAL_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_WITNESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_WITNESS_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BRANCH_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BSP_DEFAULT')
    this.$store.commit('salesProspecting/SET_SIGNATORY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_SIGNATORY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTING_BOD_DEFAULT')
    this.$store.commit('salesProspecting/SET_BUSINESSADD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_MAILINGBUSINESSADD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BUSINESSADD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_MAILINGBUSINESSADD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARYCONTACT_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_PRIMARYCONTACT_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BRANCHES_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_BRANCHES_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_OWNERS_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_OWNERS_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGOWNERS_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGOWNERS_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_BOD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_BOD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBOD_CITY_DEFAULT')
    this.$store.commit('salesProspecting/SET_EXISTINGBOD_ZIP_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOA_DEFAULT')
    this.$store.commit('bsp/UNSET_BSP_ID')
    this.$store.commit('bsp/SET_DET_DETAILS_DEFAULT')
    this.$store.commit('bsp/SET_DEF_FILES_DEFAULT')
    this.$store.commit('bsp/DET_FILES_DEFAULT')
    this.$store.commit('bsp/SET_COMMENT_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_RETURNED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_ENCODER_RETURNED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_COMPLIANCE_APPROVED_COMMENTS_DEFAULT')
    this.$store.commit('salesProspecting/SET_INITIALCOMMENT_DEFAULT')
    this.$store.commit('bsp/SET_COMPLIANCEUPLOAD_DEFAULT')
    this.$store.commit('salesProspecting/SET_RADIO_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOA_CHECKBOX_DEFAULT')
    this.$store.commit('merchantEnrollment/SET_MERCHANT_BRANCH_DEFAULT')
    this.$store.commit('salesProspecting/SET_MOTHERENTITY_DETAILS_DEFAULT')
  },
  methods: {
    async reloadList(){
        const res = await this.$store.dispatch('merchantEnrollment/doGetListEncoder')
        this.salesProspect = res.data.result
        this.salesProspect.map(e => {
            if (e.is_avp){
                this.availment.push('AVP')
            }
            if (e.is_avp){
                this.availment.push('AVP')
            }
        })

    },
    formatDate (date) {
        return format(parseISO(date), 'yyyy-MM-dd')
    },
    async checkSp(item){

        this.branch_name = []
        this.branch_error = false
        this.company_id_error = false
        this.type_of_sub_agents_error = false

        const res = await this.$store.dispatch('merchantEnrollment/doCheckSp', item)
            if (res.status === 200){

                console.log(res.data.result)

                let type_of_sub_agents = ''
                let company_id = ''

                if (res.data.result.sp_other_primary_details){

                    if (res.data.result.sp_other_primary_details.business_type_of_sub_agents){
                        type_of_sub_agents = JSON.parse(res.data.result.sp_other_primary_details.business_type_of_sub_agents)
                    } else{
                        type_of_sub_agents = null
                    }

                    if (res.data.result.sp_other_primary_details.company_id){
                        company_id = res.data.result.sp_other_primary_details.company_id
                    } else{
                        company_id = null
                    }
                }


                if (res.data.result.sp_other_primary_details){
                    let data = res.data.result.sp_other_primary_details
                    if (!data.is_avp && !data.is_nonex && !data.is_western_union){
                        this.availment_error = true
                    }
                }
                
                if(res.data.result.sp_other_primary_details == null){
                    this.snackbar = true
                    this.company_id_error = true
                    this.type_of_sub_agents_error = true

                    if(res.data.result.sp_branch.length != 0){
                        this.branch_error = true
                        res.data.result.sp_branch.map(item => {
                            this.branch_name.push(item.branch_name)
                        })
                    }

                } else if (company_id == null || company_id == '' || type_of_sub_agents == null || type_of_sub_agents == '' || res.data.result.sp_branch.length != 0){
                    this.snackbar = true
                    if (company_id == null || company_id == ''){
                        this.company_id_error = true
                    }

                    if (type_of_sub_agents == null || type_of_sub_agents == ''){
                        this.type_of_sub_agents_error = true
                    }

                    if(res.data.result.sp_branch.length != 0){
                        this.branch_error = true
                        res.data.result.sp_branch.map(item => {
                            this.branch_name.push(item.branch_name)
                        })
                    }

                } else {
                    this.$router.push('/merchant-enrollment/' + item);
                }
            }
    }
  }
}
</script>

<style scoped>
.scrollable-content {
  max-height: 500px;
  overflow-y: auto;
}

/* Hide the scrollbar */
.scrollable-content::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: transparent;
}
</style>